import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BlogCard } from './BlogCard';
import { BlogPost } from './Blog';
import { BlogLayout } from './BlogLayout';

// Everything except the body
export type BlogIndexPostData = Omit<BlogPost, 'data'> & {
	data: Omit<BlogPost['data'], 'body'>;
};

export const BlogIndex = () => {
	// allPrismicBlogPost(sort: { fields: first_publication_date, order: DESC }, limit: 4) {
	const {
		allPrismicBlogPost: { nodes }
	} = useStaticQuery<{ allPrismicBlogPost: { nodes: BlogIndexPostData[] } }>(graphql`
		query GET_RECENT_POSTS {
			allPrismicBlogPost(sort: { fields: first_publication_date, order: DESC }) {
				nodes {
					uid
					url
					first_publication_date
					data {
						title
						subtitle
						category
						hero_image {
							url
							alt
							gatsbyImageData(height: 200, placeholder: BLURRED)
						}
					}
				}
			}
		}
	`);

	return (
		<BlogLayout currentCategory={''}>
			<div className="constrain-width-lg grid grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-10">
				{nodes.map((postWithoutBody, index) => {
					return (
						<BlogCard
							key={'key-' + index}
							queryResult={postWithoutBody}
							isHero={index === 0}
						/>
					);
				})}
			</div>
		</BlogLayout>
	);
};
