import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { CtaSimple } from '../components/CTABlock';
import { BlogIndex } from '../components/Blog/BlogIndex';

//main page
const BlogPage = () => {
	return (
		<Layout>
			<SEO
				pageTitle="Blog Index"
				description="Learn all about feedback, product management, and how to build better products."
			/>

			<BlogIndex />

			<div className="mt-20">
				<CtaSimple eventPageName="blogIndex" />
			</div>
		</Layout>
	);
};

export default BlogPage;
