import React from 'react';
import { Link } from 'gatsby';
import { BlogIndexPostData } from './BlogIndex';

export const BlogCard = ({
	queryResult,
	isHero
}: {
	queryResult: BlogIndexPostData;
	isHero?: boolean;
}) => {
	return (
		<div
			className={`shadow-xl hover:shadow-2xl ${
				isHero ? 'md:col-span-2' : ''
			}  link-on-hover rounded-md overflow-hidden`}
		>
			<Link to={queryResult.url}>
				<img
					className={`object-cover w-full ${isHero ? '' : ''}`}
					src={queryResult.data.hero_image.url}
					alt={queryResult.data.hero_image.alt}
				/>
				<div className="p-6">
					{queryResult.data.category && (
						<div className="inline-block px-4 py-1 text-xs text-white bg-blue-400 rounded-full mb-4">
							{queryResult.data.category}
						</div>
					)}
					<div className="mb-4 text-lg font-bold ">{queryResult.data.title}</div>
					{<p className="font-light text-md">{queryResult.data.subtitle}</p>}
				</div>
			</Link>
		</div>
	);
};
